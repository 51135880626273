import React from "react"
import {Helmet} from "react-helmet"
import '../assets/css/main.css'
import StaticHeader from "../components/StaticHeader/StaticHeader"
import Footer from '../components/Footer/Footer'
import TableRow from "../components/TableRow/TableRow"
import NavBar from '../components/NavBar/NavBar'

// Add additional classes to the cells by separating cell value and class names with a pipe
const rows = [
  [
    "Average Household Income",
    "$430,000"
  ],
  [
    "Average Net Worth",
    "$2,600,000"
  ],
  [
    "Investing Affluencer",
    "50%"
  ]
]

const rows2 = [
  [
    "Graduated College",
    "92%"
  ],
  [
    "Post Graduate Degree",
    "64%"
  ],
  [
    "Countries where Foreign Affairs is present",
    ""
  ],
  [
    "- Print",
    "54"
  ],
  [
    "- Digital",
    "90"
  ]
]

const rows2a = [
  [
    "Top Management/C-Suite",
    "21%"
  ],
  [
    "Sits on Board of Directors",
    "28%"
  ],
  [
    "C-Suite Responsibilities",
    "55%"
  ]
]

const rows4 = [
  [
    "Average Time Spent With Each Issue",
    "104 min."
  ],
  [
    "Foreign Affairs provides me with in-depth perspectives not found elsewhere",
    "97%"
  ],
  [
    "Foreign Affairs exposes me to influential ideas before other sources do",
    "93%"
  ]
]

const rows5 = [
  [
    "Avg. Books Purchased Each Year",
    "20"
  ],
  [
    "- History",
    "85%"
  ],
  [
    "- Politics/Current Events",
    "62%"
  ],
  [
    "- Biography",
    "55%"
  ],
  [
    "- Businesss",
    "20%"
  ],
]

const rows6 = [
  [
    "Average Domestic Trips Per Year",
    "6"
  ],
  [
    "Average Foreign Trips Per Year",
    "7"
  ],
  [
    "Own a Valid Passport",
    "93%"
  ],
  [
    "Luxury travelers / Jet setters",
    "27%"
  ],
  [
    "Trips planning to take in the next year",
    "10"
  ],
  [
    "Enjoys high quality purchases",
    "81%"
  ],
  [
    "Values craftsmanship and design",
    "72%"
  ]
]

const TableClasses = "max-w-3xl mx-auto w-full mt-10 mb-10"

const Demographics = (props) => (
  <>
    <Helmet>
      <title>Foreign Affairs Mediakit - Demographics</title>
    </Helmet>
    <NavBar home={false} />
    <StaticHeader
      title="Demographics"
      subtitle="Where Insight and influence intersect."
      intro="<p>For over a century, <i>Foreign Affairs</i> readers have been thoughtful and ambitious-and have trusted us as the  world's leading publication for sharp analysis of important issues.</p>

      <p>Our audience is the new generation of business leaders, those who understand how major forces will powerfully impact global society over the next decade. These forward-thinking individuals have to adopt different perspectives on their role and purpose. <i>Foreign Affairs</i> provides them authoritative analysis of vital international developments that influence how they respond for the good of their organization and stakeholders.</p>"
    />
    <div className="max-w-screen-xl mx-auto px-5">
      <TableRow headings="" rows={rows} classes={TableClasses} footer="" />
      <TableRow headings="" rows={rows2} classes={TableClasses} footer="" />
      <TableRow headings="" rows={rows2a} classes={TableClasses} footer="" />
      <TableRow headings="" rows={rows4} classes={TableClasses} footer="" />
      <TableRow headings="" rows={rows5} classes={TableClasses} footer="" />
      <TableRow headings="" rows={rows6} classes={TableClasses} footer="" />
    </div>
    <Footer />
  </>
)

export default Demographics
